import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './components/home/home';
import BecomeanAffiliate from './components/become-an-affiliate/becomeanAffiliate';
import NavBar from './components/navbar/navbar';
import NavbarSection from './components/navbar/navbar';
import { HelmetProvider } from 'react-helmet-async';
import NavbarSecion from './components/navbar/navbar';
import FightingPromotions from './components/fighting-promotions/FightingPromotions';
import AboutUs from './components/about-us/AboutUs';
import ContactUs from './components/contact-us/ContactUs';
import TermsOfService from './components/terms-of-service/TermsOfService';
import CookiePolicy from './components/cookie-policy/CookiePolicy';
import PrivacyPolicy from './components/privacy-policy/PrivacyPolicy';
import logo from "./assets/logo.png";


const theme = createTheme({
  palette: {
    primary: {
      main: '#DEC371'
    },
    secondary: {
      main: '#fff'
    }
  },
});

const Layout = ({ children }) => {

  // const targetDateComingSoon = new Date("2024-12-10T22:45:00");

  const targetDateComingSoon = new Date(Date.UTC(2024, 11, 15, 5, 0, 0)); 
  // December 15, 2024, at 00:00:00 EST is 05:00:00 UTC
  //Time in EST is UTC +5 hours

  const [isOpened, setIsOpened] = useState(false);
  const [timeLeftComingSoon, setTimeLeftComingSoon] = useState(calculateTimeLeftComingSoon());
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowContent(true); // Show content after 2 seconds
    }, 1000);

    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, []);

  function calculateTimeLeftComingSoon() {
    const now = new Date();
    const difference = targetDateComingSoon - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      return { days, hours, minutes, seconds };
    } else {
      return null; // No time left
    }
  }

  //for coming soon
  useEffect(() => {
    const timer = setInterval(() => {
      const remainingTime = calculateTimeLeftComingSoon();
      setTimeLeftComingSoon(remainingTime);

      // If countdown is done, update the state
      if (!remainingTime) {
        setIsOpened(true);
        clearInterval(timer); // Stop the timer
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeftComingSoon]);


  return (
    <>
      {isOpened ?
        <>
          <NavbarSecion />
          {children}
        </>:
        (
          <div className="coming-page d-flex align-items-center justify-content-center">
          {showContent && (
            <>
            <div className="text-center">
              <div>
                <img src={logo} className="logo pb-5"></img>
              </div>
              <h1>Coming Soon</h1>
              <div className="sub-text pb-4">We’re currently working on creating something fantastic.
              We’’ll be here soon.</div>
              {/* {timeLeftComingSoon && (
                <div className="d-flex justify-content-center">
                  <div className="count-box">
                    <div className="count">{timeLeftComingSoon.days}</div>
                    <div className="title">
                      Days
                    </div>
                  </div>
                  <div className="count-box">
                    <div className="count">{timeLeftComingSoon.hours}</div>
                    <div className="title">
                    Hours
                    </div>
                  </div>
                  <div className="count-box">
                    <div className="count">{timeLeftComingSoon.minutes}</div>
                    <div className="title">
                    Minutes
                    </div>
                  </div>
                  <div className="count-box">
                    <div className="count">{timeLeftComingSoon.seconds}</div>
                    <div className="title">
                    Seconds
                    </div>
                  </div>
                </div>
              )} */}
            </div>
            </>
          )}
        </div>
        )
        }
    </>
  );
};


const router=createBrowserRouter([
  {
    path:'/fans',
    element: (
      <Layout>
        <Home/>
      </Layout>
    ),
    errorElement:<div>Wrong Route</div>
  },
  {
    path:'/become-an-affiliate',
    element: (
      <Layout>
        <BecomeanAffiliate/>
      </Layout>
    ),
  },
  {
    path:'/',
    element: (
      <Layout>
        <FightingPromotions/>
      </Layout>
    ),
  },
  {
    path:'/about-us',
    element: (
      <Layout>
        <AboutUs/>
      </Layout>
    ),
  },
  {
    path:'/contact-us',
    element: (
      <Layout>
        <ContactUs/>
      </Layout>
    ),
  },
  {
    path:'/privacy-policy',
    element: (
      <Layout>
        <PrivacyPolicy/>
      </Layout>
    ),
  },
  {
    path:'/cookie-policy',
    element: (
      <Layout>
        <CookiePolicy/>
      </Layout>
    ),
  },
  {
    path:'/terms-of-service',
    element: (
      <Layout>
        <TermsOfService/>
      </Layout>
    ),
  },
])
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </React.StrictMode>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
