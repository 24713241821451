import React from "react";
import logo from "../../assets/ko-logo.png";
import "./footer.scss";
import facebookIcon from "../../assets/facebook-icon.svg";
import twitterIcon from "../../assets/twitter-icon.svg";
import instagramIcon from "../../assets/instagram-icon.svg";
import youtubeIcon from "../../assets/youtube-icon.svg";
import Nav from "react-bootstrap/Nav";
import { Link, useLocation } from "react-router-dom";


const Footer = () => {
  return (
    <div className="footer py-4 text-center text-md-start">
      <div className="container">
        <div className="row">
          <div className="col-md-6 order-2 order-md-1 pt-4 pt-md-0">
            <img src={logo} alt="Global Fight League Logo" className="pb-2 pb-md-5" />
            <div className="copyright">
            © 2025 KombatPlus. All rights reserved.
            </div>
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <div className="d-md-flex justify-content-md-end">
              <div>
                <h3>Company</h3>
                <div className="links">
                <a href="/about-us" >
                  About Us
                  </a>
                  <a href="/contact-us" >
                  Contact Us
                  </a>
                </div>
              </div>
              <div className="px-md-4">
                <h3>Legal</h3>
                <div className="links">
                <a href="/privacy-policy">Privacy Policy</a>
                  <a href="/cookie-policy">Cookie Policy</a>
                  <a href="/terms-of-service">Terms of Service</a>
                </div>
              </div>
              {/* <div>
                <h3>Follow Us</h3>
                <div className="followus">
                  <a href="#" target="_blank"><img src={facebookIcon}></img></a>
                  <a href="#" target="_blank"><img src={twitterIcon}></img></a>
                  <a href="#" target="_blank"><img src={instagramIcon}></img></a>
                  <a href="#" target="_blank"><img src={youtubeIcon}></img></a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
