import { ThemeContextProvider } from "../../themecontext";
import CommonCarousel from "../common-carousel/CommonCarousel";
import Button from "react-bootstrap/Button";
import Footer from "../footer/footer";
import "./FightingPromotions.scss";
import { useNavigate } from "react-router-dom";
import promotionsIcon1 from "../../assets/promotions-icon1.svg";
import promotionsIcon2 from "../../assets/promotions-icon2.svg";
import promotionsIcon3 from "../../assets/promotions-icon3.svg";
import promotionsIcon4 from "../../assets/promotions-icon4.svg";
import promotionsIcon5 from "../../assets/promotions-icon5.svg";
import promotionsIcon6 from "../../assets/promotions-icon6.svg";
import promotionsIcon7 from "../../assets/promotions-icon7.svg";
import promotionsIcon8 from "../../assets/promotions-icon8.svg";
import promotionsIcon9 from "../../assets/promotions-icon9.svg";
import promotionsIcon10 from "../../assets/promotions-icon10.svg";
import promotionsIcon11 from "../../assets/promotions-icon11.svg";
import promotionsImg2 from "../../assets/promotions-img2.png";
import gflLogo from "../../assets/gfl.png";
import { Helmet } from "react-helmet-async";
import slider1Img1 from "../../assets/slider1-img1.png";
import slider1Img2 from "../../assets/slider1-img2.png";
import slider1Img3 from "../../assets/slider1-img3.png";

import slider2Img1 from "../../assets/slider2-img1.png";
import slider2Img2 from "../../assets/slider2-img2.png";
import slider2Img3 from "../../assets/slider2-img3.png";

import slider3Img1 from "../../assets/slider3-img1.png";
import slider3Img2 from "../../assets/slider3-img2.png";
import slider3Img3 from "../../assets/slider3-img3.png";

import slider4Img1 from "../../assets/slider4-img1.png";
import slider4Img2 from "../../assets/slider4-img2.png";
import slider4Img3 from "../../assets/slider4-img3.png";
import PromotionModal from "./PromotionModal";
import React, { useCallback, useEffect, useState } from "react";
import ScheduleDemoModal from "./ScheduleDemoModal";
import SignupForm from "../signup_form/SignupForm";

const FightingPromotions = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openModalPromotion, setOpenModalPromotion] = useState(false);

  const handleClose = () => setOpenModal(false);
  const handleClosePromotion = () => setOpenModalPromotion(false);

  const firstCarousel = [
    { src: slider1Img1, text: "Custom Branding" },
    { src: slider1Img2, text: "Fanbase Management" },
    { src: slider1Img3, text: "Multi-Channel Content Libraries" },
  ];

  const secondCarousel = [
    { src: slider2Img1, text: "Multi-Camera Support and Editing" },
    { src: slider2Img2, text: "Graphics Overlays and Stats" },
    { src: slider2Img3, text: "Accessible From Anywhere" },
  ];

  const thirdCarousel = [
    { src: slider3Img1, text: "Ticketing & PPV" },
    { src: slider3Img2, text: "Subscriptions" },
    { src: slider3Img3, text: "Advertising & Sponsorship" },
  ];

  const fourthCarousel = [
    { src: slider4Img1, text: "Live Chat and Polls" },
    { src: slider4Img2, text: "Q&A and Virtual Meet & Greets" },
    { src: slider4Img3, text: "Content Personalization" },
  ];

  const BecomeanAffiliate = () => {
    navigate("/become-an-affiliate");
  };

  return (
    <>
      <Helmet>
        <title>
          Kombatplus - Transform Your Combat Sports Promotion into a
          Full-Featured Media Network
        </title>
        <meta
          property="og:title"
          content="Kombatplus - Transform Your Combat Sports Promotion into a Full-Featured Media Network"
        />
        <meta
          name="description"
          content="Kombatplus revolutionizes combat sports promotions by transforming them into fully-branded media networks. Manage content, engage fans with interactive features, and monetize through PPV, subscriptions, and affiliate programs. Leverage Web3 and AI for personalized experiences and increase your reach globally."
        />
        <meta
          name="keywords"
          content="combat sports media network, MPaaS, promotion monetization, live streaming, fan engagement tools, custom branding, affiliate program, Web3 for promotions, AI-powered fan experience, multi-channel content, subscription models, PPV, digital ticketing, sponsorship, NFT marketplace, sports content production, affiliate sales growth, Kombatplus platform."
        />
      </Helmet>
      <ThemeContextProvider>
        <div className="fighting-promotions">
          <div className="hero-section">
            <div className="container text-center">
              <div className="row justify-content-center">
                <div className="col-md-12">
                  <h1>Transform Your Promotion into a Media Network</h1>
                  <hr></hr>
                  <div className="sub-text pb-3">
                    <span className="logo-text px-2">
                      <span>KO</span>MBAT<span className="plus">+</span>
                    </span>
                    empowers combat sports with the tools to build, manage, and
                    monetize your own media network.
                  </div>
                  <div>
                    <Button
                      variant="primary"
                      className="me-3"
                      onClick={() => setOpenModal(true)}
                    >
                      Schedule a Demo
                    </Button>
                    {/* <Button
                      variant="outline-primary"
                      onClick={() => setOpenModalPromotion(true)}
                    >
                      Join as a Promotion
                    </Button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section2">
            <div className="container text-center">
              <div className="d-flex align-items-center justify-content-center pb-5">
                <div className="red-line"></div>
              </div>
              <h2>
                Revolutionize Your Promotion with
                <span className="logo-text ps-2">
                  <span>KO</span>MBAT<span className="plus">+</span>
                </span>
              </h2>
              <div className="row justify-content-center text-center">
                <div className="col-md-8">
                  <p>
                    More than a streaming service, it’s a complete media
                    platform built for combat sports. With live streaming, fan
                    engagement features, and exclusive content tools,
                    <span className="logo-text px-2">
                      <span>KO</span>MBAT<span className="plus">+</span>
                    </span>
                    empowers promotions to connect with fans like never before.
                  </p>
                </div>
              </div>
              <div className="row text-center features pt-4">
                <div className="col">
                  <img src={promotionsIcon1}></img>
                  <div className="name pt-4">Content Distribution</div>
                </div>
                <div className="col">
                  <img src={promotionsIcon2}></img>
                  <div className="name pt-4">Revenue Generation</div>
                </div>
                <div className="col">
                  <img src={promotionsIcon3}></img>
                  <div className="name pt-4">Engagement Tools</div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="carousel-section text-center py-2 py-md-5">
              <h3 className="pb-3">Operate as Your Own Media Network</h3>
              <p className="pb-4">
                <span className="logo-text pe-1">
                  <span>KO</span>MBAT<span className="plus">+</span>
                </span>
                gives each promotion the independence of a fully-branded
                network.
              </p>
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <CommonCarousel contents={firstCarousel} />
                </div>
              </div>
            </div>
            <div className="carousel-section text-center py-2 py-md-5">
              <h3 className="pb-3">Produce and Stream Content from Anywhere</h3>
              <p className="pb-4">
                High-quality production, without costly infrastructure.
              </p>
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <CommonCarousel contents={secondCarousel} />
                </div>
              </div>
            </div>
            <div className="carousel-section text-center py-2 py-md-5">
              <h3 className="pb-3">Turn Your Content into Revenue</h3>
              <p className="pb-4">
                Powerful monetization tools for every revenue model.
              </p>
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <CommonCarousel contents={thirdCarousel} />
                </div>
              </div>
            </div>
            <div className="carousel-section text-center py-2 py-md-5">
              <h3 className="pb-3">Bring Fans Closer Than Ever Before</h3>
              <p className="pb-4">
                Our platform’s interaction tools make every event an experience.
              </p>
              <div className="row justify-content-center">
                <div className="col-md-9">
                  <CommonCarousel contents={fourthCarousel} />
                </div>
              </div>
            </div>
            <div className="section3 pt-5 pb-2 pb-md-5">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h3>Empowering Promotions with Web3 and AI</h3>
                  <p>
                    Bringing Web3 and AI advancements to enhance fan and
                    promotion experiences.
                  </p>
                </div>
                <div className="col-md-6 text-center">
                  <div className="features-box p-3">
                    <h4 className="pb-3">Web3 Features</h4>
                    <div className="row pb-4">
                      <div className="col">
                        <img src={promotionsIcon4} className="pb-2"></img>
                        <div>
                          Token-Based <br></br> Payments
                        </div>
                      </div>
                      <div className="col">
                        <img src={promotionsIcon5} className="pb-2"></img>
                        <div>Smart Contracts for Revenue Sharing</div>
                      </div>
                      <div className="col">
                        <img src={promotionsIcon6} className="pb-2"></img>
                        <div>NFTs for Exclusive Collectibles</div>
                      </div>
                    </div>
                    <h4 className="pb-3">AI-Enhanced Experience</h4>
                    <img src={promotionsIcon7} className="pb-2"></img>
                    <div>
                      AI-driven personalization, chatbot <br></br>support.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section4 py-5">
              <div className="row gx-md-5">
                <div className="col-md-6">
                  <img src={promotionsImg2} className="mw-100"></img>
                </div>
                <div className="col-md-6">
                  <h3>Expand Your Audience with Affiliate Partners</h3>
                  <p className="pb-4 sub-text">
                    Drive ticket sales, subscriptions, and PPV purchases with
                    affiliate partners.
                  </p>
                  <div className="row text-center features">
                    <div className="col-6 pb-3">
                      <img src={promotionsIcon8} className="pb-3"></img>
                      <h6 className="pb-2">Affiliate Sales Growth</h6>
                      <p>
                        Leverage affiliate marketing to increase reach and
                        revenue.
                      </p>
                    </div>
                    <div className="col-6 pb-3">
                      <img src={promotionsIcon9} className="pb-3"></img>
                      <h6 className="pb-2">Real-Time Sales Tracking</h6>
                      <p>
                        Affiliates track conversions, subscriptions, and PPV
                        sales through their custom dashboard.
                      </p>
                    </div>
                    <div className="col-6 pb-3">
                      <img src={promotionsIcon10} className="pb-3"></img>
                      <h6 className="pb-2">Automated Payouts</h6>
                      <p>
                        Smart contracts for secure, timely payouts, plus reward
                        tiers for top performers.
                      </p>
                    </div>
                    <div className="col-6 pb-3">
                      <img src={promotionsIcon11} className="pb-3"></img>
                      <h6 className="pb-2">Performance Optimization</h6>
                      <p>
                        Affiliates reward you for sharing, watching, and
                        engaging more on{" "}
                        <span className="logo-text ps-1">
                          <span>KO</span>MBAT<span className="plus">+</span>
                        </span>
                        .
                      </p>
                    </div>
                  </div>
                  <div className="text-center">
                    <Button
                      variant="primary"
                      onClick={BecomeanAffiliate}
                      className="px-4"
                    >
                      Become an Affiliate
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="section5 py-2 py-md-5">
              <div className="text-center pb-5">
                <h3>
                  See Why Promotions Choose
                  <span className="logo-text ps-2">
                    <span>KO</span>MBAT<span className="plus">+</span>
                  </span>
                </h3>
                <p>
                  Hear success stories from promotions that transformed with
                  Kombatplus.
                </p>
              </div>
              <div className="gfl-box">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-4">
                    <img src={gflLogo} className="mw-100"></img>
                  </div>
                  <div className="col-md-6">
                    <h3>The Platform Enabling the Global Fight League</h3>
                    <div className="row pb-3 justify-content-center justify-content-md-start">
                      <div className="col-4">
                        <div className="red-line"></div>
                      </div>
                    </div>
                    <p>
                      The Global Fight League App runs on KOMBAT+, providing a
                      powerful solution for combat sports promotions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section6 my-5 d-flex align-items-center justify-content-center text-center">
            <div>
              <h3>Transform Your Promotion Today</h3>
              <p className="pb-3">
                Join the
                <span className="logo-text px-2">
                  <span>KO</span>MBAT<span className="plus">+</span>
                </span>
                ecosystem and take your promotion to the next level.
              </p>
              <div>
                <Button
                  variant="primary"
                  className="px-4"
                  onClick={() => setOpenModal(true)}
                >
                  Schedule a Demo
                </Button>
              </div>
            </div>
          </div>
        </div>
        {openModal && (
          <ScheduleDemoModal openModal={openModal} handleClose={handleClose} />
        )}
        {openModalPromotion && (
          <PromotionModal
            openModal={openModalPromotion}
            handleClose={handleClosePromotion}
          />
        )}
        {/* <SignupForm /> */}
        <Footer />
      </ThemeContextProvider>
    </>
  );
};
export default FightingPromotions;
